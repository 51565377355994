<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rimborso</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaRimborso()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente non censito -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Cliente (se non censito)
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.cliente_manuale" placeholder="Es. Mario Rossi"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rimborso.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Luogo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Partenza
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.luogo" placeholder="Udine, via Roma 25"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Luogo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Arrivo
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.luogo_arrivo" placeholder="Milano, Corso Como 20"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Motivo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Motivo
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.motivo" placeholder="Es. incontro con cliente"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Km effettuati -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Km effettuati <small>Lasciare vuoto per calcolo automatico</small></div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.km" placeholder="Es. 245"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <button type="submit" class="btn_rimborso">
                                Inserisci rimborso
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonText,
    IonInput,
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { openToast } from "@/services/toast";

import apiClienti from "@/services/clienti";
import apiRimborsi from "@/services/rimborsi";

import apiLeads from "@/services/leads";

export default defineComponent({
    name: "ModalNuovoRimborso",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonText,
        IonInput,
        IonIcon,
    },
    setup(props, context) {
        //console.log(props.data);

        const router = useRouter();

        const recall_date = new Date();

        const userID = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_user_id; //set userID in user field
        const dipendenteID = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_id;

        const data_viaggio = new Date();
        const rimborso = reactive({
            cliente: "",
            cliente_manuale: "",
            data: data_viaggio.toISOString(),
            motivo: "",
            luogo: "",
            km: "",
        });

        const lead = reactive({
            cliente: null,
            titolo: "",
            email: "",
            telefono: "",
            data_richiamo: "",
            prezzo: 0,
            descrizione: "",
        });

        const successResponse = ref(false);

        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }
        /**
         * Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            }
        }

        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return customers.value.filter((cliente) => {
                //company, nome/cognome, province
                if (cliente.customers_company) {
                    return (
                        cliente.customers_company
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else {
                    if (cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_last_name + cliente.customers_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_name + cliente.customers_last_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (cliente.customers_name && !cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (!cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    }
                }
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
                //imposta il cliente sul rimborso
                rimborso.cliente = customer.customers_id;
            }
        }

        const setActiveCustomer = computed(() => {
            return (customer, selectedCustomer) => {
                let className = "";

                if (selectedCustomer) {
                    if (customer.customers_id === selectedCustomer.customers_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Create new spesa
         */
        async function creaRimborso() {
            //Data obbligatoria
            if (!rimborso.data) {
                openToast("La data è obbligatoria", "toast_danger");
                return;
            }

            if (!rimborso.luogo) {
                openToast("Il luogo di partenza è obbligatorio", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("rimborsi_km_agente", userID); //logged user
            data.append("rimborsi_km_dipendente", dipendenteID); //logged user
            data.append("rimborsi_km_cliente", rimborso.cliente);
            data.append("rimborsi_km_clienti", rimborso.cliente_manuale); //logged user
            data.append("rimborsi_km_data", moment(rimborso.data).format("YYYY-MM-DD")); //logged user
            data.append("rimborsi_km_luogo", rimborso.luogo); //logged user
            data.append("rimborsi_km_luogo_arrivo", rimborso.luogo_arrivo); //logged user
            data.append("rimborsi_km_motivo", rimborso.motivo); //logged user
            data.append("rimborsi_km_km", rimborso.km); //logged user
            data.append("rimborsi_km_fake", "1");

            apiRimborsi
                .saveRimborso(data)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status !== 0) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data;
                        //console.log(res);
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res[0]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la creazione del rimborso", "toast_danger");
                });
        }

        onMounted(() => {
            loadCustomer();
        });

        return {
            arrowBackOutline,
            rimborso,
            creaRimborso,
            closeModal,
            customers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            searchQuery,
            searchedCustomers,
            setSelectedCustomer,
            selectedCustomer,
            setActiveCustomer,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.example {
    border: 1px solid black;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}
.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --highlight-height: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

/* .btn_rimborso {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  } */
.btn_rimborso {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}

/* .btn_foto {
    --background: #0284c7;
    --background-activated: #0369a1;
    --background-hover: #0369a1;
    --background-focused: #0369a1;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
  } */
.btn_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
}

/* Image rules */
.img_photo {
    position: relative;
    max-height: 300px;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #dc2626;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
ion-button {
    --color: #ffffff;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}
</style>
